/* jshint undef: true, unused: true, indent: 4, jquery: true */
/* global document, window, enquire */

$(document).ready(function() {

    $('#main-form').validationEngine({'showOneMessage' : true, 'promptPosition' : 'topLeft'});

    $('footer a').click(function(e){
        e.preventDefault();
        e.stopImmediatePropagation();
        var day = new Date();
        var id = day.getTime();
        window.open($(this).attr('href'), id, 'toolbar=0,scrollbars=1,location=0,statusbar=0,menubar=0,resizable=0,width=400,height=300,left = 500,top = 500');
        return false;
    });

    // Make form stick on desktop screen sizes
    enquire.register("screen and (min-width:767px)", function() {
          $(window).load(function(){
            $("#form").sticky({
                topSpacing: 50,
                bottomSpacing:280,
                responsiveWidth: true
            });
          });

    }, true );

});